import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import Seo from "../../components/seo";
import VideoPlayer from "../../components/post/video";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Playcook | Atharva Patil" mdxType="Seo" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAD/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABSBTY1JDgv//EABkQAQEAAwEAAAAAAAAAAAAAAAIBAAMSEf/aAAgBAQABBQJnrWzLfJhBiQPPVz//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPwFJ/8QAGhABAAIDAQAAAAAAAAAAAAAAAQAQAhEhQf/aAAgBAQAGPwJBiGOtelHI8r//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhUTFBYXGR/9oACAEBAAE/ISqW9Qwt3Bkj2I+dxxKZ3AJyCer4T//aAAwDAQACAAMAAAAQUO//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxB3pKP/xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAgEBPxAlW//EABoQAQEAAwEBAAAAAAAAAAAAAAERACExUWH/2gAIAQEAAT8QM1ANrS8yQBE0Ae/abxcdISOPctCJGLi7wzmlFVkMiwQQ0eR8z//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Kayak cover image",
          "title": "Kayak cover image",
          "src": "/static/d5de1309ab4fc7a3404f73c59be4cafa/e5166/cover.jpg",
          "srcSet": ["/static/d5de1309ab4fc7a3404f73c59be4cafa/f93b5/cover.jpg 300w", "/static/d5de1309ab4fc7a3404f73c59be4cafa/b4294/cover.jpg 600w", "/static/d5de1309ab4fc7a3404f73c59be4cafa/e5166/cover.jpg 1200w", "/static/d5de1309ab4fc7a3404f73c59be4cafa/d9c39/cover.jpg 1800w", "/static/d5de1309ab4fc7a3404f73c59be4cafa/df51d/cover.jpg 2400w", "/static/d5de1309ab4fc7a3404f73c59be4cafa/08688/cover.jpg 2500w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`A Wearable bluetooth cooking assistant for an iOS Application`}</h2>
    <b>Design + iOS development | Side Project | 2019</b>
    <hr></hr>
    <p>{`Meow Meow`}</p>
    <VideoPlayer linkHash="fr3CMrypq_s" mdxType="VideoPlayer"></VideoPlayer>
The video has audio cues.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      